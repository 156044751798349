:root {
  --font-color: #000;
  --pink: #a4a4a4;
  --bg: #fff;
}

@font-face {
  font-family: 'Adelia';
  src: local('adelia'), url(./fonts/adelia.ttf) format('truetype');
}


@font-face {
  font-family: 'AutumnWind';
  src: local('autumnwind'), url(./fonts/autumnwind.ttf) format('truetype');
}

body {
  background: var(--bg);
  color: var(--font-color);
  margin-bottom: 40px;
}

.App {
  text-align: center;
}

.links {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-evenly;
  margin-bottom: 20px;
  background-color: var(--bg);
  z-index: 9999;
  border-bottom: 2px solid #c5c5c5;
  font-size: 12px;
}

.venue-pic {
  display: block;
  text-align: center;
  overflow: hidden;
}

.josh-btn {
  background-color: var(--bg);
  border: 1px solid black;
  border-radius: 20px;
  padding: 2em;
  min-width: 10em;
  font-weight: bold;
  font-size: 1em;
  margin-top: 20px;
  color: var(--pink);
}

.josh-btn:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.josh-btn:active {
  cursor: pointer;
  background-color: var(--pink);
  color: #fff;
}

a {
  color: var(--font-color);
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.reactive {
  display: flex;
  flex-direction: row;
}

.smolwidth {
  width: 80%;
}

.center {
  margin-left:auto;
  margin-right:auto;
}

.flex.center {
  justify-content: center;
}

.flex.spread {
  justify-content: space-between;
}

.flex.spread2 {
  justify-content:space-around;
}

.padding20 {
  padding: 1.5em;
}

.padding40 {
  padding: 1.5em;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.cursor-pointer:hover {
  cursor: pointer;
}



.caption {
  font-size: 12px;
  text-align: center;
  width: 384px;
}

a:hover {
  color: var(--pink)
}


.title-text {
  margin-top: 60px;
  font-size: 60px;
  letter-spacing: 15px;
  font-family: Georgia, 'Times New Roman', Times, serif
}

.title-subtext {
  font-size: 20px;
  letter-spacing: 15px;
  margin-top: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif
}

.title-date {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #e384af;
  margin-top: 24px;
}

.divider {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 2px;
  width: 100%;
  background-color: #c5c5c5;
  margin-left: auto;
  margin-right: auto;
}

.divider.thin {
  height: 2px;
  margin-top: 40px;
  margin-bottom:40px;
}

.divider-thin {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  width: 80%;
  background-color: var(--font-color);
  margin-left: auto;
  margin-right: auto;
}

.home-pics-outer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.home-pics {
  display: block;
  width: 100%;
}

.home-img {
  filter: grayscale(1);
  transition: all 1s ease;
}

.home-img:hover {
  filter: grayscale(0);
}

.two-col {
  display: flex;
  justify-content: center;
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.two-col > *:first-child {
  width: 40%;
  text-align: left;
}

.two-col > *:last-child {
  width: 50%;
}

.script {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 40px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
}

.left-paragraph {
  text-align: justify;
  margin-bottom: 40px;
}

.guest-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 80%;
  border: 1px solid black;
  border-radius: 4px;
  overflow: hidden;
}


.guest-form-name {
  display: block;
  width: 400px;
  text-align: left;
  font-weight: bold;
}

.guest-form-name-checkbox {
  width: 200px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
}

.guest-form-rsvp-holder {
  display: flex;
  width: 50px;
  height: 21px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.guest-form-rsvp-holder.on {
  background-color: #8cc996;
}

.guest-form-rsvp-holder:hover {
  cursor: pointer;
}

.form-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.form-input {
  font-size: 1em;
  color: var(--pink);
}

.form-input-hold {
  width: 60%;

}

.rsvp-switch {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #FFFFF0;
  border: 1px solid black;
  margin-top: 2px;
}

.rsvp-switch.off {
  margin-left: 2px;
  margin-right: auto;
}

.rsvp-switch.on {
  margin-right: 2px;
  margin-left: auto;
}

.guest-form-rsvp-yesno {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}

.guest-form-rsvp-yesno.pink {
  color: var(--pink);
}

.guest-form-rsvp-yesno.grey {
  color: #e9e9db;
}

.guest-form-part2 {
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
}

.day-checkbox {
  display: block;
  border: 1px solid black;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
}

.form-input {
  width: 100%;
}

.pin-input {
  width: 45%;
  text-align: center;
  font-size: 3.5em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--pink);
  outline: none;
}

.menu-input {
  margin-left: 20px;
}

.menu-input:hover {
  cursor: pointer;
}

.menu-label {
  margin-top: auto;
  margin-bottom: auto;
}

.menu-label:hover {
  cursor: pointer;
}

.full-width-text {
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify;
}
.full-width-text > a, .left-paragraph > a, .left-paragraph > b > a, .accom-hold > div > a  {
  color: var(--pink);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--pink);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--pink) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1184px) {
        .links {
          /*margin-top: 20px;*/
        }
        .two-col {
          flex-direction: column-reverse;
          justify-content: center;
        }
        .two-col > *:first-child {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        
        .two-col > *:last-child {
          width: 80%;
          margin-left: 10%;
        }

        .caption {
          width: auto;
        }

        .guest-form {
          width: 90%;
        }

        .flex.reactive {
          display: flex;
          flex-direction: column;
        }

        .padding20 {
          padding: 0.5em;
        }

        .guest-form-part2:last-child {
          margin-bottom: 20px;
        }

        .form-input-hold {
          margin-left: auto;
          margin-right: auto;
        }
}
